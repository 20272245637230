/* eslint-disable no-unused-vars */
import React from 'react'
// eslint-disable-next-line no-unused-vars
import {graphql} from 'gatsby'
import Container from '../components/container'
import BlockContent from '../components/block-content'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

const ContactPage = () => (
  <Layout>
    <Container>
      <SEO title='Eric Hu studio: Contact' />
      <div className='mt-20 px-4 mb-4 mx-auto lg:max-w-4xl'>
        <div className='text-xl lg:text-2xl tracking-tight lg:mx-auto'>
          <p>For general inquiries and availabilities: <a className='underline' href='mailto:studio@erichu.info'>studio@erichu.info</a></p>
          <p>For a wider sample of work including commercial projects, feel free to include a request for a PDF portfolio in your message.</p>
          <p>Instagram: <a className='underline' href='http://instagram.com/_erichu' target='_blank'>@_erichu</a></p>
          <p>LinkedIn: <a className='underline' href='https://www.linkedin.com/in/er1chu/' target='_blank'>Profile</a></p>
        </div>
        {/* Begin Mailchimp Signup Form */}
        <div id='mc_embed_signup' className='py-4'>
          <form action='https://erichu.us7.list-manage.com/subscribe/post?u=7115e2dcfb78f2643bb9177ee&id=a015ede039' method='post' id='mc-embedded-subscribe-form' name='mc-embedded-subscribe-form' className='validate' target='_blank' noValidate>
            <div id='mc_embed_signup_scroll'>
              <h2>For newsletter updates:</h2>
              <div className='mc-field-group'>
                <label htmlFor='mce-EMAIL'>Email Address&nbsp;
                </label>
                <input type='email' name='EMAIL' className='required email border border-black' id='mce-EMAIL' />
              </div>
              <div id='mce-responses' className='clear'>
                <div className='response' id='mce-error-response' style={{display: 'none'}} />
                <div className='response' id='mce-success-response' style={{display: 'none'}} />
              </div>    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
              <div style={{position: 'absolute', left: '-5000px', opacity: '0'}} className='hidden' aria-hidden='true'><input type='text' name='b_7115e2dcfb78f2643bb9177ee_a015ede039' tabIndex={-1} defaultValue /></div>
              <div className='clear'><input type='submit' defaultValue='Subscribe' name='subscribe' id='mc-embedded-subscribe' className='rounded-full button bg-white px-4 py-1 border-black border' /></div>
            </div>
          </form>
        </div>
        {/* End mc_embed_signup */}
      </div>
    </Container>
  </Layout>
)

export default ContactPage
